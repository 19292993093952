.containerfile {
    width: 100%;
}

.containerfile .listfile {
    background-color: white;
    border-radius: 10px;
    border: 1px #cecece;
}

.containerfile .listfile .content {
    margin-right: 20px;
}

.modalfile {
    width: 40% !important;
}

.ant-upload.ant-upload-drag .ant-upload {
    padding: 0 !important
}

.ant-upload.ant-upload-drag {
    border: 0px solid !important;
    border-bottom: 1px solid rgb(116 116 121 / 28%) !important;
    box-shadow: 0px 0px 1px 1px rgb(227 232 239);
}
.image{
    font-size:50px;
    margin-left: 10px;
}
.iconxlxs{
    color:#1b4e24;
    margin-right: 20px;
}
.iconword{
    color: rgb(37 92 175);
    margin-right: 20px;
    font-size: 35px;
}
.icontxt{
    color: rgb(53 51 45 / 46%);
    margin-right: 20px;
}
.iconpng{
    color: rgb(12 12 12 / 69%);
    margin-right: 20px;
}
.icon {
    color: #443720;
    margin-right: 20px;
}
.iconpdf {
    color:#e60000b8;
    margin-right: 20px;
}
.folder{
    color:#f1a700fa;
    margin-right: 13px;
}
.iconmp4{
    margin-right: 20px;
}
.iconpdf{
    color: #f30915;
    margin-right: 20px;
}
span.ant-tree-title{
    text-overflow: ellipsis;
    display: inline-block;
    width: 106px;
    white-space: nowrap;
    overflow: hidden;
}
.panelcard .ant-card-body{
    padding: 0;
    padding-top: 3px;
}
/* .backgroundtable .ant-table-placeholder{
    background-color:white;
} */