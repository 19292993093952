.menu-item-flex {
    display: flex;
    .ant-dropdown-menu-item-icon {
        line-height: 22px;
    }
    span {
        flex: 1;
        display: flex;
        .menu-item-flex-label {
            flex: 1;
        }
        .ant-switch {
            margin-top: 3px;
        }
    }
}
// .ant-layout {
//     background-color: #f0f2f5;
// }

body[theme="drak"] {
    .search-overlay-dropdown {
        .ant-divider {
            color: #ccc;
            border-color: #eee;
        }
        .ant-form-item-label > label {
            color: #ccc;
        }
        .ant-input-affix-wrapper {
            background-color: #1a2e40;
            .anticon {
                color: #ccc;
            }
            input {
                background-color: #1a2e40;
                color: #ccc;
            }
        }
        background: #244059;
        color: #ccc;
    }
    .ant-dropdown-menu {
        background: #244059;
        .ant-dropdown-menu-item,
        .ant-dropdown-menu-submenu-title,
        .ant-dropdown-menu-item > a,
        .ant-dropdown-menu-item > .anticon + span > a {
            color: rgba(255, 255, 255, 0.65);
        }

        .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
        .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
        .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
        .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
            color: rgba(255, 255, 255, 0.65);
        }
        .ant-dropdown-menu-item:hover,
        .ant-dropdown-menu-submenu-title:hover,
        .ant-dropdown-menu-item > a:hover,
        .ant-dropdown-menu-item > .anticon + span > a:hover {
            color: #fff;
            background: transparent;
        }
        .ant-dropdown-menu-item-selected,
        .ant-dropdown-menu-item-selected:hover,
        .ant-dropdown-menu-item-selected > a {
            color: #fff;
            background: #1890ff;
        }
        .ant-dropdown-menu-item-divider {
            background-color: #6b7986;
        }
    }
}
