.page-builder-component-draggable {
    margin: 5px 0;
}

body.page-building .smooth-dnd-ghost.page-builder-component-draggable {
    visibility: hidden !important;
}

body.page-building .animated.page-builder-component-draggable {
    visibility: visible !important;
}

body.page-building .content-card-page-builder>.ant-card-body>.smooth-dnd-container>.animated, body.page-building .smooth-dnd-container>.smooth-dnd-draggable-wrapper {
    transform: none !important;
}

body.page-building .smooth-dnd-container.vertical>.smooth-dnd-drop-preview-constant-class {
    height: 2px !important;
    max-height: 2px !important;
    position: absolute;
}

body.page-building .smooth-dnd-container.vertical>.smooth-dnd-drop-preview-constant-class>.smooth-dnd-drop-preview-flex-container-class>.smooth-dnd-drop-preview-inner-class.page-builder-component-drop-preview {
    background-color: rgb(0, 0, 0, .25);
    border-top: 2px solid green;
    z-index: 9999;
}

body.page-building .smooth-dnd-stretcher-element.vertical {
    max-height: 2px;
}

body.page-building .page-builder-component {
    padding: 10px;
    border: 1px solid transparent;
}

body.page-building .page-builder-component.page-builder-component-preview {
    padding: 0;
    border: 0;
}

body.page-building .page-builder-component.page-builder-component-view-component {
    border-style: dotted;
    border-color: #393939;
}

body.page-building .page-builder-component.page-builder-component-hover {
    border-style: solid;
    border-color: #1890ff;
}

body.page-building .page-builder-component.page-builder-component-dragging {
    border-style: solid;
    border-color: green;
}

body.page-building .page-builder-component, body.page-building .page-builder-component>.smooth-dnd-container {
    height: 100%;
}

body.page-building .page-builder-component-item-draggable {
    position: relative;
    padding-top: 25px;
}