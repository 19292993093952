.ant-form {
    .ant-form-item-label > label,
    .ant-picker-input > input,
    .ant-input {
        font-size: 14px;
    }

    .ant-picker {
        width: 100%;
    }

    .ant-form-item-label > label {
        white-space: break-spaces;
    }
}
.ant-form-vertical {
    .ant-row.ant-form-item {
        padding: 0 10px;
        .ant-form-item-label {
            flex: 1;
            max-width: 100%;
            padding-bottom: 0;
        }
    }
}
