.list-schema{
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  padding: 7px 10px !important;
  border-bottom: none !important;
}
.item-name-form-hover{
  color: rgba(0, 0, 0, 0.85);
  transition: .3s ease;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.item-name-form-hover:hover{
  color: #1890ff !important;
}
.button--add{
  position: relative;
  top: -15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
  .span--models{
    font-size: 14px;
    color: rgb(98, 110, 153);
    font-weight: 500;
  }
  .span-create-form{
    color: #1890ff;
  }
  .span-create-form:hover{
    cursor: pointer;
  }
  button:hover{
    border: 1px solid #1890ff;
  }
}
.list-schema-active{
  background-color: rgb(242, 241, 255);
  transition: .3s ease;
}
.list-schema-active .item-name-form-hover{
  color: #1890ff;
}
.list-schema-active p{
  color: #1890ff;
  transition: .3s ease;
}
.list__border--not{
  border: none;
} 
.container-content{
  display: flex;
  justify-content: center; 
  &__div{
    width: 720px;
    min-height: 73px;
    display: flex;
    padding: 10px;
    border-radius: 4px;
    &--icon{
      position: relative;
      top: 14px;
      font-size: 20px;
      margin-right: 10px;
    }
    &--code{
      margin-right: 10px;
      padding: 10px;
      width: 60px;
      height: 54px;
      font-size: 20px;
      background-color: rgb(234, 253, 239);
      text-align: center;
      color: rgb(39, 103, 73);
      border-radius: 4px;
    }
    &--content{
      &-name{
        display: flex;
      flex-wrap: wrap;
        padding-right: 20px;
        span{
          font-size: 18px;
          font-weight: 500;
        }
        .span-id{
          padding-top: 5px;
          font-size: 14px;
          box-sizing: border-box;
          min-width: 0px;
          overflow: hidden;
          max-width: 200px;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: rgba(40, 45, 76, 0.54);
          padding-left: 3px;
        }
      }
      &-const{
        span{
          padding: 3px 6px;
          border-radius: 4px;
          margin-right: 4px;
          color: rgb(72, 84, 130);
          background-color: rgb(240, 242, 247);
        }
      }
    }
  }
}
.container-validation{
  margin-top: 20px;
  &__p{
    padding-left: 25px;
    color: rgb(98, 110, 153);
  }
}
.make-field{
  display: none;
  padding: 20px 20px 20px 20px;
  background-color: rgb(253, 253, 234);
  &__p--content{
    color: rgb(142, 75, 16);
  }
}
.container-option{
  padding-left: 25px;
  &--validation-min-max{
    margin: 0 10px;
  }
  &__div-input{
    label{
      margin-left: 10px;
    }
    input{
      margin-top: 5px;
    }
  }
}
.card__font-weight--bold{
  height: 100%;
  .ant-card-head-title{
    font-weight: bold;
  }
}
.card-field-type{
  background: rgb(248, 248, 248);
  border: none;
}
.container-field.disabled::before{
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  cursor: no-drop;
  border-radius: 5px;
}
.container-field{
  position: relative;
  display: flex;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  padding: 8px;
  transition: .3s ease;
  &__div-code{
    margin-right: 10px;
    div{
      width: 43px;
      height: 43px;
      padding: 10px;
      background-color: rgb(234, 253, 239);
      
      text-align: center;
      color: rgb(39, 103, 73);
    }
  }
  &__div--code{
    padding: 0;
    margin: 0;
    font-weight: bold;
  }
  &__div--title{
    padding: 0;
    margin: 0;
    color: rgb(98, 110, 153);
  }
} 
.modal-confirm-form .ant-modal-confirm-title{
  font-weight: 550;
  color: #626e99;
} 
.content-btn-hover > .ant-btn:not(.ant-popover-open){
  opacity: 0;
}
body:not([moving="1"]) .container-content:hover .content-btn-hover > .ant-btn{
  opacity: 1
}
.limit-width .ant-form-item-control{
  min-height: auto;
}
.limit-inputNumber .ant-form-item{
  margin-bottom: 10px;
}
.pattern-margin .ant-form-item{
  margin-bottom: 10px;
}
.form-margin .ant-form-item{
  margin-bottom: 0px;
}
.make-field-initial{
  padding: 10px;
  background-color: #95acc2d7;
  p{
    margin: 0;
    padding: 0;
    padding-left: 10px;
    color: rgb(104, 105, 105);
  }
}
.div-number-map .ant-input-number{
  width: 100%;
}
//destinations
.destination-checkbox .ant-form-item{
  margin-bottom: 10px !important;
}
.destination-checkbox .ant-checkbox-wrapper{
  // font-size: 16px;
  // color: #8b874e;
}
.destination-checkbox .ant-checkbox-inner{
  height: 16px;
  width: 16px;
  border: 1px solid #a0a9b2 !important;
  border-radius: 2px;
}
.destination-collapse .ant-collapse-arrow{
  svg{
    // color: #0d1b49;
    // color: #c56c23f8;
    color: #3fbfdf;
  font-size: 18px;
  }
}
.destination-collapse .ant-collapse-header{
  // color: #0d1b49 !important;
  // color: #c75a00 !important;
  color: #3fbfdf !important;
  background-color: white;
  font-size: 18px !important;
  padding: 6px 16px !important;
}
.destination-box-container{
  position: relative;
}
.destination-box-container:hover{
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
  // box-shadow: 0 8px 60px 0 rgba(103, 151, 255, .11), 0 12px 90px 0 rgba(103, 151, 255, .11);
  transition: 0.2s ease;
}
.destination-box-title h2{
    margin: 0;
    // color: #970a11;
    // color: #113581;
    color: #0a7922;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 240px;
}
.destination-box-overlay{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .2s ease;
  border-radius: 6px;
  z-index: 1;
  &-description{
    height: 50%;
    // background-color: #217c614f;
    background-color: #dfe5e24f;
    div{
      display: flex;
      justify-content: center;
    }
    div h2{
      margin-top: 3px;
      margin-bottom: 15px;
      // color: #970a11;
      // color: #23458d;
      // color: #34850e;
      color: #0a7922;
      font-weight: 500;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 240px;
    }
    div p{
      padding: 0 5px;
      margin: 0;
      text-align: center;
      // color: #fff;
      color: #c56c23f8;
      font-size: 16px;
      height: 50px;
      overflow: hidden;
    }
  }
  &-action{
    height: 50%;
    background-color: white;
    .action-button{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 25px;
      .ant-form-item{
        .ant-btn{
          font-size: 11.5px !important;
        }
        margin-bottom: 10px;
        
      }
      .button-create{
        .ant-btn-primary{
          background-color: #69bd43;
          border-color: #69bd43;
        }
        .ant-btn-primary:hover{
          opacity: 0.9;
        }
      }
      .button-add{
        .ant-btn-primary{
          background-color: #1AB394;
          border-color: #1AB394;
        }
        .ant-btn-primary:hover{
          opacity: 0.9;
        }
      }
    }
  }
}
.destination-box-container:hover .destination-box-overlay{
  height: 100%;
}

.search-tour{
  .ant-btn-primary{
    background-color: #358f48ee;
    border-color: #358f48ee;
    // background-color: #0a7922;
    // border-color: #0a7922;
  }
  .ant-btn-primary:hover{
    opacity: 0.9;
  } 
}

.view-more-tours{
  .ant-btn-primary{
    background-color: #3fbfdf;
    border-color: #3fbfdf;
    color: #c56c23f8;
  }
  .ant-btn-primary:hover{
    opacity: 0.9;
  }
}

