.ant-tree-node-content-wrapper {
    display: flex;
}

.ant-tree-node-content-wrapper>.ant-tree-title {
    flex: 1;
}

.ant-back-top {
    bottom: 100px;
}

.ant-form-item-label {
    text-align: left;
    padding-left: 10px;
}

.ant-form-item-label>label, .form-item-group-name {
    color: #666a6f;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '(*)';
}

tr.ant-table-placeholder>td.ant-table-cell {
    border-bottom: 1px solid #f0f0f0;
}

.ant-menu-inline-collapsed .ant-menu-submenu>.ant-menu-submenu-title>span {
    opacity: 0;
}

.ant-tree .ant-tree-treenode {
    width: 100%;
}

.ant-tree .ant-tree-node-content-wrapper {
    width: inherit;
}

.ant-menu-hidden, .ant-menu-submenu-hidden {
    display: none;
}

.ant-tabs-tab {
    margin: 0;
    padding: 12px;
}

.header-menu-none-border-bottom>.ant-menu-item, .header-menu-none-border-bottom>.ant-menu-submenu {
    border-bottom: 2px transparent !important;
}

.ant-carousel-custom-container {
    position: relative;
    overflow: hidden;
}

.ant-carousel-custom-container>.ant-carousel-custom-btn {
    position: absolute;
    top: calc(50% - 50px);
    bottom: calc(50% - 50px);
    width: 50px;
    background-color: #00000036;
    z-index: 1;
    color: #fff;
    cursor: pointer;
    line-height: 120px;
    text-align: center;
    transition: all .3s;
}

.ant-carousel-custom-container>.ant-carousel-custom-btn.ant-carousel-custom-btn-left {
    left: -50px;
}

.ant-carousel-custom-container:hover>.ant-carousel-custom-btn.ant-carousel-custom-btn-left {
    left: 0px;
}

.ant-carousel-custom-container>.ant-carousel-custom-btn.ant-carousel-custom-btn-right {
    right: -50px;
}

.ant-carousel-custom-container:hover>.ant-carousel-custom-btn.ant-carousel-custom-btn-right {
    right: 0px;
}

.ant-pagination-hide {
    display: none !important;
}

.ant-message .ant-message-loading>.anticon>svg {
    margin-top: 0;
}

.alertconfig .ant-alert-message {
    display: block;
    margin-bottom: 4px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: 0 !important;
}

.checkbox-img .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #429f18;
    border-radius: 50%;
    border-color: #429f18;
}

.checkbox-img span .ant-checkbox-inner {
    border-radius: 50%;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 40px !important
}

.ant-modal-mask, .ant-modal-wrap {
    z-index: 1001;
}

.cover-des .ant-descriptions-item-container {
    display: block !important;
}

.cover-des span.ant-descriptions-item-label {
    border-bottom: 1px solid #dbd3d387;
    width: 100%;
}

.cover-des span.ant-descriptions-item-content {
    padding: 15px;
}

/* .imagegalery .image-gallery-slides {
    min-height: 600px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.customupload .image-gallery-slides {
    min-height: 400px;
    display: flex;
    align-items: center;
    overflow: hidden;
} */

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end){
    background: #f5f5f5;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-selected{
    background: #1890ff;
}
.ant-picker-cell .ant-picker-cell-inner{
    width: 100%;
}