.product-item {
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
    border: 1px solid #dddfe2;
    border-radius: 10px;
    background: "#fff";
}
.product-position {
    position: relative;
}
.item__image {
    position: relative;
    overflow: hidden;
    padding-top: 71.14285714%;
    border-radius: 10px 10px 0 0;
}
.img-fluid {
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    right: unset;
    bottom: unset;
    object-fit: cover;
    transition: transform 200ms ease,-webkit-transform 200ms ease;
}
.tour-item__title {
    font-weight: 700;
    font-size: 16px;
    color: #2d4271;
    line-height: 20px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    min-height: 4rem;
    max-height: 4rem;
}
.tour-price-number {
    font-weight: 700;
    color: #ff4949;
}
.tour-price {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 10px;
}
.product-position .p-3 {
    padding: 10px;
}

.tour-item__title {
    font-weight: 700;
    font-size: 16px;
    color: #2d4271;
    line-height: 20px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    min-height: 60px;
    max-height: 60px;
}
.tour-item__title a{
    color: #2d4271;
}
.s-rate {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.s-rate .rate-span {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    background: #fdc432;
    width: 42px;
    height: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-right: 11px;
}
.s-comment {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.s-comment h4 {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #2d4271;
    margin-bottom: 0;
    margin-right: 10px;
}
.s-comment span{
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #2d4271;
    margin-bottom: 0;
    margin-right: 10px;
}
.s-comment span:last-child {
    border: 0;
}
.tour-item_icon-favorite {
    font-size: 22px;
    color: #fff;
    top: 5%;
    left: 3%;
    position: absolute;
    background-color: rgba(0,0,0,.1);
    border-radius: 8px;
    padding: 2px 5px;
    cursor: pointer;
}
.tour-item_icon-favorite i {
    line-height: 1px;
    display: inline-block;
    color: #fff;
}
.tour-item_summary {
    top: 5%;
    right: 3%;
    position: absolute;
}
.tour-item_summary .tour-item_rating {
    align-items: center;
    margin-bottom: 0.5rem;
    -webkit-box-pack: end!important;
    justify-content: flex-end;
    -webkit-box-align: center;

}
.tour-item_summary .tour-item_rating span{
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #fff;
    background: #fdc432;
    width: 40px;
    height: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-left: 3rem;
}
.tour-item_review {
    color: #fff;
    background-color: rgba(0,0,0,.1);
    border-radius: 8px;
    padding: 5px;
}
.tour-item_review p {
    text-align: "right"; 
    margin-bottom: "0.25rem"
}
.btn-detail {
    display: flex;
    justify-content: flex-end;
}

.tour-btn {
    margin-top: 15px;
}
.ant-comment-product .ant-comment-inner {
    padding: 0 !important;
}

/*  */

.change-quantily {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    margin-bottom: 13px;
    padding: 10px 15px;
    background: #fff;
}

.change-quantily-title h4 {
    font-weight: 700;
    font-size: 15px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(44, 73, 100);;
    margin-bottom: 0;
}
.change-quantily-number {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.change-quantily-number .btn-click {
    font-size: 20px;
}
.change-quantily-number .number {
    border: none;
    background: 0 0;
    width: 30px;
    text-align: center;
    font-size: 22px;
    line-height: 32px;
    color: rgb(44, 73, 100);;
}
.btn-order-sp {
    background: linear-gradient(64.4deg,#fd5056 21.33%,#fe2214 67.61%);
    border-radius: 10px;
    height: 50px;
    width: 70%;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
}
.bg-order {
    background: rgb(249, 249, 249);
    border: 1px solid #f0f0f0;
    border-radius:8px;
    margin-bottom: 15px;
}