/* Du lich Gia Lai CSS by NghiaLH */
/* All import style here */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.0/font/bootstrap-icons.css");

/* Main colors */
:root {}

/* Font Styles */
body.app-tourism *:not(.fa):not(#hero p):not(#hero h2):not(#thingtodo .title h2) {
  font-family: "Nunito", Arial !important;
}

body.app-tourism {
  font-family: "Nunito", Arial;
  color: #444;
}

a, a:hover, a:focus {
  text-decoration: none;
  transition: all ease 500ms;
}

a:hover, a:focus {
  color: var(--item-info-a-hover-color);
}

h1, h2 {
  font-family: "Quicksand", Arial;
}

@media (min-width: 992px) {
  .container-fluid {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.justify-content-right {
  justify-content: right !important;
}

.flex-1 {
  flex: 1 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  background: var(--navigation-background);
  height: 40px;
  font-size: 14px;
  color: var(--navigation-color);
  transition: all 0.5s;
  z-index: 996;
}

#topbar.topbar-scrolled {
  top: -40px;
}

#topbar .contact-info a {
  line-height: 1;
  color: #ffffff;
  transition: 0.3s;
}

#topbar .contact-info a:hover {
  color: #b0fda2;
}

#topbar .contact-info i {
  color: #b0fda2;
  padding-right: 4px;
  margin-left: 15px;
  line-height: 0;
}

#topbar .contact-info i:first-child {
  margin-left: 0;
}

#topbar .social-links i {
  margin-right: 5px;
}

#topbar .social-links a {
  color: #ffffff;
  padding-left: 15px;
  display: inline-block;
  transition: 0.3s;
  line-height: 30px;
}

#topbar .social-links a:hover {
  color: #b0fda2;
}

#topbar .social-links a:first-child {
  border-left: 0;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #fff;
  transition: all 0.5s;
  z-index: 1000;
  padding: 6px 0;
  top: 40px;
  box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
}

#header.header-scrolled {
  top: 0;
}

.me-auto {
  margin-right: auto !important;
}

#header .search {
  margin-left: 10px;
  color: #e37300;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar-brand img {
  height: 70px;
}

.navbar {
  padding: 0;
  font-family: "Quicksand", Arial;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar>ul>li {
  position: relative;
  white-space: nowrap;
  padding: 8px 0 8px 20px;
}

.navbar a, .navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: #444;
  white-space: nowrap;
  transition: 0.3s;
  border-bottom: 2px solid #fff;
  padding: 5px 2px;
}

.navbar a i, .navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover>a, .navbar .dropdown ul a.active {
  color: #e37300;
  border-color: #e37300;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 20px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 8px 20px;
  font-size: 15px;
  font-weight: 500;
  text-transform: none;
  color: #444;
  border: none;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover>a {
  color: #e37300;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #2c4964;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(28, 47, 65, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile>ul>li {
  padding: 0;
}

.navbar-mobile a, .navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #2c4964;
  border: none;
}

.navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover>a {
  color: #1977cc;
}

.navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover>a {
  color: #1977cc;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# HP Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  background-color: rgba(4, 12, 21, 0.8);
  overflow: hidden;
  position: relative;
}

#hero .carousel, #hero .carousel-inner, #hero .carousel-item, #hero .carousel-item::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#hero .carousel-item {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

#hero .carousel-item::before {
  content: "";
  background-color: rgba(4, 12, 21, 0.1);
}

#hero .carousel-container {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 20px;
  left: 50px;
  right: 50px;
}

#hero h2 {
  color: #fff;
  margin: 0;
  font-size: 130px;
  font-weight: 700;
  font-family: 'Dancing Script', san-serif;
  line-height: 1;
}

#hero p {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  color: #fff;
  font-family: 'Ephesis', sans-serif;
  margin-top: 0px;
  font-size: 90px;
  line-height: 1;
}

@media (min-width: 1200px) {
  #hero p {
    width: 60%;
  }
}

#hero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  opacity: 0;
}

#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  transition: 0.5s;
}

#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  left: 0;
  transform: translate3d(0, 0, 0);
}

#hero .carousel-control-next-icon, #hero .carousel-control-prev-icon {
  background: none;
  font-size: 30px;
  line-height: 0;
  width: auto;
  height: auto;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.5);
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#hero .carousel-control-next-icon:hover, #hero .carousel-control-prev-icon:hover {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.8);
}

#hero .carousel-indicators li {
  cursor: pointer;
  background: #fff;
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  opacity: 0.6;
  transition: 0.3s;
}

#hero .carousel-indicators li.active {
  opacity: 1;
  background: #ed502e;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 32px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  background: #ed502e;
  margin-top: 15px;
}

#hero .btn-get-started:hover {
  background: #ef6445;
}

@media (max-width: 992px) {
  #hero {
    height: 100vh;
  }

  #hero .carousel-container {
    text-align: center;
    top: 74px;
  }
}

@media (max-width: 768px) {
  #hero h2 {
    font-size: 30px;
  }
}

@media (min-width: 1024px) {
  #hero .carousel-control-prev, #hero .carousel-control-next {
    width: 5%;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  /* padding: 60px 0; */
  overflow: hidden;
}

.section-bg {
  background-color: #f1f7fd;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
  max-width: 660px;
  margin: 0 auto;
}

.section-title h2 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #e37300;
}

.section-title h2::before {
  position: absolute;
  content: "";
  bottom: 0;
  width: 50px;
  height: 3px;
  background-color: #368a24;
  left: 50%;
  transform: translateX(-50%);
  transition: width .7s;
  z-index: 1;
}

.section-title h2:hover::before {
  width: 120px;
}

.section-title h2::after {
  position: absolute;
  content: "";
  bottom: 0;
  width: 120px;
  height: 1px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ddd;
  z-index: 0;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Home - Section Nổi bật
--------------------------------------------------------------*/
.post-block, .listing-item-block {
  border: 1px solid #dce0e0;
  border-radius: 5px;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  width: 100%;
}

.post-block:hover, .listing-item-block:hover {
  zoom: 1;
  filter: alpha(opacity=100);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -webkit-transform: translateY(-4px);
  -moz-transform: translateY(-4px);
  -o-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
  -webkit-box-shadow: 0 22px 40px rgb(0 0 0 / 15%);
  -moz-box-shadow: 0 22px 40px rgba(0, 0, 0, 0.15);
  box-shadow: 0 22px 40px rgb(0 0 0 / 15%);
}

.post-item-img img, .listing-item-img img {
  width: 100%;
  height: 100%;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.post-item-img a, .listing-item-img a {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 66.66%;
}


.post-item-info, .listing-item-info {
  padding: 20px 20px;
}

.post-item-info span {
  font-weight: 600;
  color: #ee4035;
}

.post-item-info h3 {
  font-weight: 700;
  font-size: 18px;
  margin-top: 3px;
}

.post-item-info h3 a {
  color: #444;
}

.listing-item-info h3 a {
  color: #007bff;
}

.post-item-info h3 a:hover, .listing-item-info h3 a:hover {
  color: var(--item-info-a-hover-color);
}

.post-item-info p {
  text-align: justify;
}

.readmore {
  color: #444;
  opacity: 0.5;
}

.readmore:hover {
  opacity: 1;
}

.readmore i {
  margin-left: 5px;
  font-size: 14px;
}

#noibat {
  position: relative;
}

#noibat .section-bg {
  height: 100%;
  width: 100%;
  background-image: var(--noibat-background-image);
  background-position: right bottom;
  position: absolute;
  right: -200px;
  bottom: -200px;
  background-repeat: no-repeat;
  opacity: .2;
  background-color: transparent;
}

/*--------------------------------------------------------------
# Home - Section Diem den
--------------------------------------------------------------*/
#diemden {
  background-image: var(--diemden-background-image);
  background-size: cover;
  background-position: center center;
  padding: 0px 0px;
  position: relative;
}

#diemden::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center center;
  background-color: var(--diemden-bg-image);
}

#diemden .container-fluid {
  background: rgb(255 255 255 / 30%);
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
  z-index: 1;
}

.diemden-slider {
  position: relative;
}

.destination-block {
  border-radius: 5px;
  background-size: cover;
  background-position: center center;
  position: relative;
  background-color: #f9f9f9;
  width: 100%;
  height: 300px;

}

.destination-block:before {
  content: '';
  position: absolute;
  border: 1px solid #fff;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  left: -5px;
  top: -5px;
  right: -5px;
  bottom: -5px;
}

.destination-block:before {
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.destination-block:hover:before {
  visibility: visible;
  opacity: 1;
  left: 10px;
  top: 10px;
  right: 10px;
  bottom: 10px;
}

a.destination-item-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: block;
}

.destination-block .destination-item-name {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  z-index: 2;
}

.destination-item-name {
  padding: 5px 15px 15px 15px;
}

.destination-item-name h3 {
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0;
}

#diemden .swiper-button-prev:after, #diemden .swiper-button-next:after {
  content: none !important;
}

#diemden .swiper-button-prev i, #diemden .swiper-button-next i {
  font-size: 28px;
}

#diemden .swiper-button-next:hover, #diemden .swiper-button-prev:hover {
  background: #ff6909;
  color: rgb(255, 255, 255);
  box-shadow: 0px 12px 20px 0px rgb(254 76 28 / 30%);
}

#diemden .swiper-button-prev {
  left: 8px;
}

#diemden .swiper-button-next {
  right: 8px;
}

#diemden .swiper-button-next, #diemden .swiper-button-prev {
  box-shadow: 1px 1.732px 60px 0px rgb(0 0 0 / 10%);
  width: 44px;
  height: 44px;
  color: #ff6909;
  background: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  letter-spacing: 2px;
  text-align: center;
  line-height: 45px;
  border-radius: 5px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

/*--------------------------------------------------------------
# Home - Item Style - Apply for sub page also
--------------------------------------------------------------*/
.listing-item {
  margin-bottom: 30px;
}

.item-discount {
  position: absolute;
  bottom: 20px;
  left: 0;
  background: #ff4949;
  padding: 3px 10px 2px 10px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

.listing-item-block:hover .item-discount {
  left: 5px;
  -ms-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.listing-item-info p {
  margin-bottom: 0.5rem;
}

.listing-item-info i {
  font-size: 13px;
}

.item-rating {
  position: relative;
  font-size: 13px;
}

.rating {
  color: #ffa127;
}

.love {
  position: absolute;
  right: 0px;
  top: 0px;
  color: #ff4949;
}

/*--------------------------------------------------------------
# Home - Section Làm cái chi
--------------------------------------------------------------*/
#thingtodo {
  background-image: var(--thingtodo-background-image);
  background-size: cover;
  background-position: center bottom;
  padding: 0px 0px;
  position: relative;
}

#thingtodo::before {
  content: '';
  background-image: var(--thingtodo-bg-image);
  background-size: cover;
  background-position: center bottom;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

#thingtodo .container-fluid {
  background: rgb(0 0 0 / 60%);
  padding-top: 120px;
  padding-bottom: 80px;
  position: relative;
  z-index: 1;
}

#thingtodo {
  color: #ffffff;
}

#thingtodo .title h2 {
  font-family: 'Dancing Script', sans-serif;
  font-size: 60px;
  color: #fff;
}

#thingtodo .title p {
  font-size: 18px;
}

#thingtodo a, #thingtodo a:hover {
  color: #ffffff;
}

#thingtodo span {
  font-size: 26px;
  display: block;
}

#thingtodo span {
  color: #ffca2c;
}

#thingtodo a:hover span {
  color: #ffffff;
}

#thingtodo a:hover ion-icon {
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  -webkit-animation: bounce 1s;
  animation: bounce 1s;
}

ion-icon {
  font-size: 64px;
}

/*--------------------------------------------------------------
# Home - Section Video & Tin tức
--------------------------------------------------------------*/
.video-news h2 {
  margin-bottom: 20px;
  color: var(--item-info-a-hover-color);
}

.video-news .sh i {
  color: #e37300;
}

.video-container {
  width: 100%;
  padding-top: 56%;
  /* 1:1 Aspect Ratio */
  position: relative;
  /* If you want text inside of it */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.news-list .news-block {
  margin-bottom: 15px;
}

.news-block .news-thumb {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 38%;
  flex: 0 0 38%;
  width: 38%;
  display: block;
  overflow: hidden;
  margin-right: 15px;
  margin-bottom: 15px;
}


.news-block:hover .news-thumb img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.news-thumb a {
  display: block;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  overflow: hidden;
}

.news-thumb img {
  width: 100%;
  display: block;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -ms-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
  border-radius: 4px;
}

h3.news-title {
  line-height: 0.8;
  margin-top: -5px;
}

.news-title a {
  font-size: 16px;
  color: #444;
  font-weight: 600;
}

.news-title a:hover {
  color: var(--item-info-a-hover-color);
}

.news-content {
  font-size: 13px;
  color: #999;
}

/*--------------------------------------------------------------
# Home - Section Logo Liên kết
--------------------------------------------------------------*/
section#logo-lienket {
  padding: 0px 0px 20px 0px;
}

.logo-item {
  padding: 0 25px;
  height: 140px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  transition: all 0.5s;
  position: relative;
}

.logo-item img {
  margin: auto;
  filter: var(--logo-item-img-filter);
  transition: all 0.4s ease-in-out;
  max-width: 90%;
}

.logo-item:hover img {
  filter: none;
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  padding: 0px 0px;
  color: var(--footer-color);
}

#footer .container-fluid {
  background-color: var(--footer-background-color);
  padding-top: 40px;
  padding-bottom: 10px;
}

.f-email {
  margin-left: 20px;
}

#footer {
  line-height: 36px;
  font-size: 15px;
  font-weight: 400;
}

#footer h4 {
  font-size: 18px !important;
  margin-bottom: 15px;
}

.contact-info i {
  color: #91ff00;
}

.store {
  flex-flow: row wrap;
  width: 160px;
  padding: 7px 0px;
  border-radius: 5px;
  border: 1px solid #dce0e0;
  transition: all .5s;
  position: relative;
  box-shadow: 0px 5px 10px 5px rgb(0 0 0 / 10%);
}

.store:hover {
  background-color: #fff;
}

.store:hover .store-text {
  color: #444;
}

.store-icon {
  flex: 1 1 35%;
  max-width: 35%;
  position: relative;
}

.store-text {
  flex: 1 1 65%;
  max-width: 65%;
  transition: all .5s;
}

.store a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: block;
}

.app-downdload {
  margin-top: 20px;
}

.app-downdload img {
  margin-top: 5px;
}

.app-downdload p {
  margin: 0;
  padding: 0;
  line-height: 20px;
}

.app-downdload .ios {
  margin-left: 20px;
}

.social-media a {
  margin-right: 10px;
  font-size: 30px;
  transition: all .5s;
  color: var(--social-media-color)
}

.social-media a:hover {
  color: #fff;
}

.f-copyright {
  color: rgba(255, 255, 255, 0.8);
}

.f-copyright {
  border-top: solid 1px rgba(255, 255, 255, 0.2);
  margin-top: 10px;
  padding-top: 5px;
}

/*--------------------------------------------------------------
# Sub Page Hotel Listing - Section Header Background
--------------------------------------------------------------*/
#subpage-bg {
  width: 100%;
  background-size: cover;
  position: relative;
  padding: 0px;
  background-image: var(--subpage-bg-background-image);
  z-index: 1;
}

#subpage-bg::before {
  content: '';
  position: absolute;
  background-size: cover;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-image: var(--destinations-bg-image);
  z-index: 0;
}

#subpage-bg .bg-overlay {
  content: "";
  height: 100%;
  background-color: var(--subpage-bg-overlay-bacground-color);
  padding: 100px 0px;
  position: relative;
  z-index: 1;
}

#subpage-bg .container {
  text-align: center;
}

#subpage-bg h2 {
  color: #fff;
  font-size: 36px;
  font-weight: 600;
}

#hotel-listing #subpage-bg {
  background-image: var(--subpage-bg-background-image);
  background-position: top center;
}

.hotel-result {
  padding: 30px 0px;
}

.sidebar-item {
  padding: 20px 20px;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #dce0e0;
  margin-bottom: 10px;
}

h3.sidebar-title {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0px 0px 20px 10px;
}

.sidebar-title i {
  color: #ffa127;
  margin-left: 5px;
}

.item-title h4 {
  font-size: 16px;
  font-weight: 700;
  float: left;
}

.item-title .bi {
  float: right;
}

.item-title:after {
  content: "";
  display: block;
  clear: both;
}

.hotel-result .sidebar-item .item-content {
  margin-top: 10px
}

.hotel-result .by-price .item-content {
  margin-bottom: 10px;
}

.by-price .irs--flat .irs-from, .by-price .irs--flat .irs-to, .by-price .irs--flat .irs-single, .by-price .irs--flat .irs-bar, .by-price .irs--flat .irs-handle>i:first-child {
  background-color: #367005;
}

.by-price .irs--flat .irs-from:before, .by-price .irs--flat .irs-to:before, .by-price .irs--flat .irs-single:before {
  border-top-color: #367005;
}

.by-price .irs--flat .irs-handle.state_hover>i:first-child, .by-price .irs--flat .irs-handle:hover>i:first-child {
  background-color: #e37300;
}

.hotel-result .sidebar-item .item-content ul {
  padding-left: 0;
  margin-bottom: 0;
}

.hotel-result .sidebar-item .item-content ul li {
  list-style: none;
  display: block;
}

.check-item label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  margin: 0;
}

.check-item {
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 26px;
}

.check-item label .checkmark {
  position: absolute;
  top: 7px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #a0a9b2;
}

.check-item label {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  cursor: pointer;
}

.by-stars ul li i {
  margin-right: 2px;
  color: #ffa127;
  font-size: 14px;
}

.hotel-result .sidebar-item .item-content .btn-more-item {
  font-size: 14px;
  color: var(--btn-primary-bg-color);
  padding: 0;
  margin-top: 20px;
  text-decoration: none;
  display: none;
  box-shadow: none;
}

.hotel-result .sidebar-item .item-content .btn-more-item i {
  font-size: 12px;
}

.hotel-result .toolbar {
  display: flex;
}

.hotel-result .toolbar .fl-1 {
  flex: 1;
}

.hotel-result .toolbar h3 {
  font-size: 18px;
  font-weight: 600;
  /* float: left; */
  margin: 0;
  margin-right: 10px;
  /* width: calc(100% - 150px); */
}

.hotel-result .toolbar ul.toolbar-action {
  /* float: right; */
  padding-left: 0;
  margin: 0;
  /* margin-top: 4px; */
}

.hotel-result .toolbar ul.toolbar-action li {
  list-style: none;
}

.hotel-result .toolbar ul.toolbar-action li .dropdown button {
  padding: 0;
  text-decoration: none;
  font-size: 14px;
  outline: none;
}

.toolbar .btn-link {
  color: #444;
}

.toolbar .btn-link:hover {
  color: #368a24;
}

.hotel-list {
  clear: both;
  margin-top: 15px;
}

.add-wishlist {
  position: absolute;
  right: 15px;
  top: 5px;
}

.hotel-star-rating {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px 10px 5px 10px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

.hotel-star-rating {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
}

.hotel-star {
  position: relative;
  top: 10px;
}

.hotel-star i {
  margin-right: 3px;
  font-size: 13px;
  color: #ffc727;
}

.hotel-rating {
  text-align: right;
  font-size: 14px;
}

.hotel-rating strong {
  display: block;
  font-weight: 700px;
  font-size: 15px;
}

.item-price strong {
  font-weight: 600;
}

.item-address i, .item-phone i {
  color: #fc3c3c;
}

.item-actions a {
  float: right;
  width: 36px;
  height: 36px;
  margin-left: 5px;
  background: #ECF6F8;
  line-height: 40px;
  position: relative;
  color: #999;
  border-radius: 4px;
  font-size: 15px;
}

.actions-tooltip {
  position: absolute;
  opacity: 0;
  right: 0;
  top: -40px;
  height: 30px;
  line-height: 30px;
  min-width: 100px;
  margin-left: -75px;
  color: #fff;
  font-size: 11px;
  visibility: hidden;
  border-radius: 4px;
}

.more-items .load-more {
  clear: both;
  background-color: var(--btn-primary-bg-color);
  color: #fff !important;
  padding: 10px 20px !important;
  border: 1px solid transparent;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  position: relative;
  z-index: 9;
  border-radius: 4px;
  -webkit-transition: all .35s;
  transition: all .35s;
  -moz-transition: all .35s;
  -ms-transition: all .35s;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.load-more strong, .more-items .btn-action strong {
  position: relative;
  display: inline-block;
  padding-right: 0;
  -webkit-transition: all .35s;
  transition: all .35s;
}

.load-more strong:after {
  content: '\f138';
  font-family: bootstrap-icons !important;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all .35s;
  transition: all .35s;
  font-size: 10px;
}

.load-more:hover {
  -webkit-box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
  box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
  color: #fff;
  background: var(--btn-primary-bg-color);
}

.load-more:hover strong {
  padding-right: 20px;
}

.load-more:hover strong:after {
  opacity: 1;
}

/*--------------------------------------------------------------
# News List
--------------------------------------------------------------*/
.news-list {
  padding: 40px 0px;
}

span.news-date {
  font-size: 13px;
  color: #444;
  opacity: 0.5;
  letter-spacing: 0.3;
  display: block;
  margin-bottom: 6px;
}

span.news-date i {
  color: #ee4035;
  font-size: 12px;
}

/*--------------------------------------------------------------
# Hotel Detail
--------------------------------------------------------------*/
#hotel-detail #main, #listing-map #main, #map-single-item #main {
  margin-top: 120px;
}

#hotel-detail h2 {
  font-size: 30px;
  font-weight: 600;
}

#hotel-detail h3 {
  font-size: 24px;
  font-weight: 600;
}

#hotel-detail h4, #map-single-item h4 {
  font-size: 16px;
  font-weight: 600;
}

.listing-header {
  margin-bottom: 20px;
}

.listing-content {
  padding-top: 50px;
  padding-bottom: 40px;
}

.listing-name {
  position: relative;
}

.listing-stars {
  position: absolute;
  top: -20px;
  font-size: 13px;
  color: #ffbc00;
}

.listing-stars i {
  margin-right: 2px;
}

.listing-actions .btn-success, .room-info .btn-success, .map-item-filter .btn-success, .shop-action .btn-success {
  background-color: var(--btn-success-bg-color);
  border-color: var(--btn-success-bg-color);
}

.listing-actions .btn-success:hover, .room-info .btn-success:hover, .map-item-filter .btn-success:hover, .shop-action .btn-success:hover {
  background-color: var(--btn-success-hover-bg-color);
  border-color: var(--btn-success-hover-bg-color);
}

.listing-actions .btn-light {
  color: #797f89;
  background-color: #f0f3f6;
  border-color: #f0f3f6;
}

.listing-actions .btn-light:hover, .listing-actions .btn-light:focus {
  color: #fff;
  background-color: var(--item-info-a-hover-color);
  border-color: var(--item-info-a-hover-color);
}

.listing-actions .btn {
  margin-left: 5px;
}

.shop-action {
  position: absolute;
  top: 30%;
  left: 0;
  transition: all ease .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  opacity: 0;
  z-index: 9;
}

.listing-item-block:hover .shop-action {
  transform: translateY(-50%);
  opacity: 1;
}

.listing-item-block:hover .listing-item-img img {
  opacity: 0.3;
}

.shop-action .btn {
  transition: all ease .3s;
  z-index: 7;
  padding: 0;
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
  color: #fff;
  border-radius: 50%;
  margin: 5px;
}

.listing-image img, .room-photo img {
  width: 100%;
  height: auto;
}

.listing-desc {
  margin: 20px 0px;
  border-bottom: 1px solid #dce0e0;
}

.listing-rating {
  color: #fc3c3c;
  position: absolute;
  bottom: -20px;
}

.listing-rating a {
  font-size: 13px;
  color: #444;
}

.listing-rating a:hover {
  color: #55ad0f;
}

.hotel-rooms h3, .product-list h3 {
  margin-bottom: 20px;
}

.room-photo {
  background-size: cover;
  background-position: center center;
  position: relative;
  width: 100%;
  min-height: 250px;
}

.room-photo a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: block;
}

.room-photo:before {
  content: '';
  position: absolute;
  border: 1px solid #fff;
  opacity: 0;
  visibility: hidden;
  left: -5px;
  top: -5px;
  right: -5px;
  bottom: -5px;
}

.room-photo:before {
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.room-photo:hover:before {
  visibility: visible;
  opacity: 1;
  left: 10px;
  top: 10px;
  right: 10px;
  bottom: 10px;
}

.room-info {
  padding-top: 15px;
  padding-bottom: 15px;
}

.room-price {
  margin-top: 12px;
  margin-bottom: 5px;
  display: block;
}

.room-price strong {
  color: #fc3c3c;
  font-size: 20px;
  font-weight: 600;
}

.room-price strong.hasdiscount {
  color: #444;
  font-size: 15px;
  font-weight: 500;
  opacity: 0.7;
  margin-right: 3px;
  text-decoration: line-through;
}

.room-facilities {
  margin-bottom: 10px;
}

.room-facilities div {
  margin-bottom: 6px;
}

.room-facilities i {
  color: #e37300;
}

.hotel-rooms .room {
  margin-bottom: 20px;
  border: 1px solid #d7dce3;
}

.listing-right-block {
  background: #fff;
  -webkit-box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
  box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
  border: 1px solid rgba(0, 0, 0, .1);
  margin-bottom: 30px;
  border-radius: 4px;
}

.listing-map {
  width: 100%;
  min-height: 200px;
}

.listing-contact-info {
  padding: 15px 15px;
}

.listing-contact-info i {
  font-size: 13px;
  color: #fc3c3c;
}

.related-items {
  padding: 15px 15px 5px 15px;
}

.related-items h3 {
  font-size: 20px !important;
  font-weight: 600;
  margin-bottom: 15px;
}

.related-item-photo {
  background-size: cover;
  background-position: center center;
  position: relative;
  width: 100%;
  min-height: 200px;
  margin-bottom: 15px;
}

.related-item-photo a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: block;
}

.related-item-info {
  position: absolute;
  bottom: 5px;
  left: 10px;
  color: #fff;
  z-index: 10;
}

.related-item-info span {
  display: block;
}

.related-item-info i {
  margin-right: 2px;
  color: #ffe843;
  font-size: 13px;
}

.related-item-photo:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(60%, transparent), color-stop(66%, rgba(0, 0, 0, 0.1)), color-stop(93%, rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.49)));
  background-image: -webkit-linear-gradient(transparent 0%, transparent 60%, rgba(0, 0, 0, 0.1) 66%, rgba(0, 0, 0, 0.5) 93%, rgba(0, 0, 0, 0.49) 100%);
  background-image: linear-gradient(transparent 0%, transparent 60%, rgba(0, 0, 0, 0.1) 66%, rgba(0, 0, 0, 0.5) 93%, rgba(0, 0, 0, 0.49) 100%);
}

/*--------------------------------------------------------------

/*--------------------------------------------------------------
# News List
--------------------------------------------------------------*/
.news-list {
  padding: 40px 0px;
}

span.news-date {
  font-size: 13px;
  color: #444;
  opacity: 0.5;
  letter-spacing: 0.3;
  display: block;
  margin-bottom: 6px;
}

span.news-date i {
  color: #ee4035;
  font-size: 12px;
}

#news-listing #subpage-bg {
  background-image: var(--news-listing-subpage-bg-background-image);
  background-position: center center;
}

#news-listing #subpage-bg .bg-overlay {
  background-color: rgba(0, 229, 240, 0.3);
}

#news-listing .post-block {
  box-shadow: 0px 0px 10px 1px rgb(71 85 95 / 8%);
  -webkit-box-shadow: 0px 0px 10px 1px rgb(71 85 95 / 8%);
  -moz-box-shadow: 0px 0px 10px 1px rgba(71, 85, 95, 0.08);
}

#news-listing .post-block:hover, #news-listing .post-block:focus {
  -webkit-box-shadow: 0 10px 30px 0 rgb(58 87 135 / 15%);
  -moz-box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
  box-shadow: 0 10px 30px 0 rgb(58 87 135 / 15%);
}

#news-listing .more-items {
  text-align: center;
}

/*--------------------------------------------------------------
# Map Listing - Grid Block View
--------------------------------------------------------------*/
.gialai-map {
  top: 80px;
  height: 100vh;
  display: block;
  position: fixed;
}

@media (min-width: 768px) {
  .gialai-map {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .gialai-map {
    width: 58.33333%;
  }
}

.listing-map-item {
  margin-bottom: 20px;
  position: relative;
}

.listing-map-item:hover .map-item-image {
  transform: scale(1.2);
}

.map-item-photo:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  background: linear-gradient(to top, rgba(0, 0, 0, .7) 15%, rgba(0, 0, 0, .3) 30%, rgba(0, 0, 0, 0) 40%);
  border-radius: 5px;
}

.map-item-filter {
  padding: 20px 0px 10px 0px;
}

.map-item-photo {
  overflow: hidden;
  position: relative;
  border-radius: 5px;
}

.map-item-image {
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 300px;
  border-radius: 5px;
  transition: all .5s;
  position: relative;
}

.map-item-image:before {
  content: " ";
  background: linear-gradient(to bottom, rgba(24, 24, 24, .6) 20%, rgba(24, 24, 24, 0) 100%);
  top: 0;
  height: 90px;
  border-radius: 5px;
  width: 100%;
  display: block;
}

a.map-item-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: block;
}

.map-item-top {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px 15px 5px 15px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  z-index: 2;
}

.map-item-top .open-status {
  text-transform: uppercase;
  font-size: 12px;
}

.map-item-top .has-saleoff {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  padding: 3px 5px;
  background-color: #ff6a00;
  border-radius: 4px;
}

.map-item-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  z-index: 2;
}

.map-item-bottom {
  padding: 5px 15px 15px 15px;
}

.map-item-rating i {
  margin-right: 2px;
}

.map-item-bottom h3 {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0;
  margin: 0;
  line-height: 1.5
}

.map-item-bottom p {
  margin: 0;
  font-size: 14px;
}

.map-item-bottom i {
  font-size: 13px;
  color: #ff6a00;
}

/*--------------------------------------------------------------
# Map Listing - List View
--------------------------------------------------------------*/
.map-item-result.list-view {
  flex-basis: 100%;
  max-width: 100%;
}

.list-view .map-item-image:before {
  height: 40px;
}

.list-view .listing-map-item {
  flex-flow: row wrap;
  align-content: flex-start;
  position: relative;
  align-items: flex-start;
  display: flex;
}

.list-view .map-item-image {
  position: relative;
  max-width: 100%;
  height: 130px;
}

.list-view .map-item-photo {
  flex: 1 1 40%;
  max-width: 40%;
  position: relative;
}

.list-view .map-item-image img {
  position: absolute;
  display: block;
  max-width: 100%;
  height: auto;
}

.list-view .map-item-top {
  position: absolute;
  max-width: 40%;
  padding: 5px 5px;
}

.list-view .map-item-bottom {
  flex: 1 1 60%;
  max-width: 60%;
  padding: 0 10px;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  display: flex;
  color: #444;
}

.map-item-count {
  padding-top: 10px;
}

.view-type a {
  color: #444;
}

.view-type a:hover, .view-type a:focus {
  color: #ff6a00;
}

.view-type a.active {
  color: #ff6a00;
}

.view-type i {
  font-size: 20px;
  margin-left: 5px;
}

/*--------------------------------------------------------------
# Product PopUp
--------------------------------------------------------------*/
.listing-item-popup .modal-body, .listing-item-popup .carousel-item, .listing-item-popup .listing-item-info {
  height: 400px
}

.listing-item-popup .carousel-item {
  background-size: cover;
  background-position: center center;
}

.listing-item-popup .listing-item-info h4 {
  font-weight: 700;
  font-size: 18px;
}

.listing-item-popup .item-price {
  margin-top: 8px;
}

.listing-item-popup .item-price strong {
  color: #368a24;
}

.listing-item-popup p {
  text-align: justify;
}

.listing-item-popup .listing-item-info {
  padding: 20px 20px;
  background-color: #fff;
}

.listing-item-popup button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 2px 2px;
  background-color: #fff;
}

/*--------------------------------------------------------------
# Trip Planning
--------------------------------------------------------------*/
.trip-planning .listing-content {
  padding-top: 20px;
}

.trip-info {
  flex: 1 1 80%;
  max-width: 80%;
  position: relative;
}

.trip-detail .trip-info {
  flex: 1 1 95%;
  max-width: 95%;
}

.trip-actions {
  flex: 1 1 20%;
  max-width: 20%;
  position: relative;
  display: flex;
  height: 80px;
}

.trip-detail .trip-actions {
  height: 20px;
  flex: 1 1 5%;
  max-width: 5%;
  font-size: 22px;
}

.trip-detail .trip-actions .btn-outline-danger:hover {
  color: #f12539;
  background-color: transparent;
  border-color: #f12539;
}

.trip-detail .card-header .btn {
  padding: 0.05rem 0.3rem;
}

.trip-actions .btn {
  margin-left: 5px;
}

.trip-list .trip-item {
  flex-flow: row wrap;
  align-content: flex-start;
  position: relative;
  align-items: flex-start;
  display: flex;
}

.trip-list ul {
  margin: 0;
  padding: 0;
}

.trip-list ul li {
  list-style-type: none;
}

.trip-list ul li .trip-item {
  padding: 15px 15px;
  border: 1px solid rgba(0, 0, 0, .1);
}

.trip-list ul li {
  margin-bottom: 20px;
}

.trip-list ul li .trip-item {
  -webkit-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
  position: relative;
  border-radius: 5px;
}

.trip-list ul li:hover .trip-item {
  box-shadow: 0 0 30px rgb(0 0 0 / 10%);
  border: 1px solid rgba(0, 0, 0, .3);
}

.trip-list ul li h4>a {
  color: #444;
}

.trip-list ul li:hover h4>a {
  color: #368a24;
}

.trip-info h4 {
  font-size: 18px !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.trip-budget, .trip-locations {
  margin-left: 20px;
}

.trip-planning .list-view .map-item-image {
  height: 110px;
}

.trip-planning .related-items h4 {
  margin-bottom: 0;
}

.trip-day {
  font-size: 18px;
  font-weight: 700;
  color: #55ad0f;
}

.trip-detail .card-header {
  padding: 1rem 1.25rem;
}

.trip-detail .trip-list {
  padding-left: 15px;
}

.trip-list ul.trip-timeline li {
  position: relative;
  padding: 0 0 5px 30px;
}

.trip-list ul.trip-timeline {
  border-left: 1px dashed #d5d5d5;
}

.trip-timeline-line {
  position: absolute;
  left: -15px;
  top: 0;
  z-index: 9;
  font-size: 20px;
  color: #ffa127;
}

.trip-timeline-line span {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  color: #55ad0f;
  border: solid 1px #55ad0f;
  font-size: 16px;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}

.trip-list ul li:hover .trip-timeline-line span {
  background: #55ad0f;
  color: #fff;
  border: solid 1px #55ad0f;
}

.trip-detail .rating, .trip-detail .trip-info i {
  font-size: 13px;
}

.trip-detail .rating strong {
  font-size: 15px;
  margin-left: 10px;
  font-weight: 600;
}

.trip-detail .trip-info h4 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.trip-detail .trip-info p {
  margin-bottom: 3px;
}

.trip-detail .trip-info .item-price strong {
  font-weight: 600 !important;
  font-size: 16px !important;
}

.trip-detail .item-address i, .trip-detail .item-phone i {
  color: #55ad0f !important;
}

.trip-detail .card-header {
  background-color: #fff;
}

.trip-detail .trip-map {
  height: 400px;
  margin-bottom: 25px;
}

.contact-info h1 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 0;
}

.block {
  background: #fff;
  -webkit-box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
  box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
  border: 1px solid rgba(0, 0, 0, .1);
  margin-bottom: 30px;
  border-radius: 4px;
  margin-top: 10px;
}

.news-items {
  padding: 15px 15px 5px 15px;
}

.news-items .title-color {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: #e37300;
}

.news-items-block {
  margin-bottom: 5px;
  display: flex
}

.news-items-block .img-news {
  -webkit-box-flex: 0;
  /* -ms-flex: 0 0 38%; */
  /* flex: 0 0 38%; */
  /* width: 38%; */
  display: block;
  overflow: hidden;
  margin-right: 15px;
  margin-bottom: 15px;
}

.news-items-block .news-thumb .imgnews {
  width: 100%;
  display: block;
  transition: all .5s ease 0s;
  border-radius: 4px;
  -webkit-transition: all .5s ease 0s
}

.news-items-block .news-content {
  font-size: 13px;
  color: #999;
}

.news-items-block:hover .news-thumb .imgnews {
  transform: scale3d(1.1, 1.1, 1.1)
}

.more-list .li-news {
  margin-bottom: 3px;
  font-size: 16px;
}

.buton-info .btn {
  margin-left: 5px;
}

.more-list .li-news a {
  color: #444;
}

.more-list .li-news a:hover {
  color: #368a24
}

.buton-info .btn-light {
  color: #797f89;
  background-color: #f0f3f6;
  border-color: #f0f3f6;
}

.buton-info .btn-light:hover, .buton-info .btn-light.btn-light-active {
  color: #fff;
  background-color: var(--item-info-a-hover-color);
  border-color: var(--item-info-a-hover-color);
}

.trip-detail .rating, .trip-detail .trip-info i {
  font-size: 13px;
}

.trip-detail .rating strong {
  font-size: 15px;
  margin-left: 10px;
  font-weight: 600;
}

.trip-detail .trip-info h4 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.trip-detail .trip-info p {
  margin-bottom: 3px;
}

.trip-detail .trip-info .item-price strong {
  font-weight: 600 !important;
  font-size: 16px !important;
}

.trip-detail .item-address i, .trip-detail .item-phone i {
  color: #55ad0f !important;
}

.trip-detail .card-header {
  background-color: #fff;
}

.trip-detail .trip-map {
  height: 400px;
  margin-bottom: 25px;
}

.preview-page-news {
  margin: 10px 0px;
}

.preview-page-news .ant-card-head {
  border-bottom: none !important
}

/* .preview-page-news .ant-card-head {
  margin-top: 10px;
} */

.preview-page-news .ant-card-head-wrapper {
  height: 38px;
}

.preview-page-news .ant-card-body, .preview-page-news .ant-card-head {
  padding: 0 !important
}

.preview-page-news .ant-card-body h2 {
  margin-bottom: .5rem !important
}

.news-detail-content {
  margin: 10px 0px;
}

.time {
  font-size: 14px;
  color: #444;
}

.more-news h3 {
  margin-bottom: .5rem !important
}

.more-list {
  margin-top: 0;
  margin-bottom: 1rem;
}

.more-list .li-news {
  /* height: 25px; */
  margin-bottom: 3px;
}

.listing-right-block {
  background: #fff;
  -webkit-box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
  box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
  border: 1px solid rgba(0, 0, 0, .1);
  margin-bottom: 30px;
  border-radius: 4px;
}

.related-news-items {
  padding: 15px 15px 5px 15px;
}

.related-news-items h2 {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: #e37300;
}

.related-news {
  margin-bottom: 5px;
}

.related-news .news-title a {
  font-weight: 600;
}

.related-news-items {
  padding: 15px 15px 5px 15px;
}

.related-news-items h2 {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: #e37300;
}

.news-block .news-thumb {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 38%;
  flex: 0 0 38%;
  width: 38%;
  display: block;
  overflow: hidden;
  margin-right: 15px;
  margin-bottom: 15px;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.news-thumb a {
  display: block;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  overflow: hidden;
}

.news-thumb img {
  width: 100%;
  display: block;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -ms-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
  border-radius: 4px;
}

.media {
  display: flex;
  align-items: flex-start;
}

.modal-detail-product .ant-modal-body {
  padding: 0px;
}

.modal-detail-product .ant-modal-body .listing-item-info {
  max-height: 400px;
  overflow: auto;
}

.modal-detail-product .ant-modal-body .modal-detail-product-slider {
  height: 400px;
}

.modal-detail-product .ant-modal-body .modal-detail-product-slider-item {
  height: 400px;
  background-size: cover !important;
  background-position: center center !important;
}

.modal-detail-product .ant-modal-body .ant-carousel .slick-dots {
  margin: 0;
}

/*--------------------------------------------------------------
# Update Tháng 4/2023
--------------------------------------------------------------*/
a.vr-bt {
  color: #fff;
  height: 48px;
  width: 200px;
  font-weight: 700;
  border-radius: 30px;
  background-size: 200%, 1px;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
  background-image: -webkit-gradient(linear, left top, right top, from(#50af00), color-stop(50%, #ff8100), to(#50af00));
  background-image: linear-gradient(90deg, #50af00 0%, #ff8100 50%, #50af00);
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

a.map-bt {
  color: #fff;
  height: 46px;
  width: 198px;
  font-weight: 700;
  border-radius: 30px;
  border: solid 1px rgba(255, 255, 255, 0.5);
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  margin-left: 5px;
}

a.vr-bt i, a.map-bt i {
  margin-left: 5px;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
}

a.vr-bt:hover {
  background-position: 120%;
}

a.map-bt:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

a.vr-bt:hover i, a.map-bt:hover i {
  margin-left: 8px;
}

.cta-buttons {
  position: absolute;
  bottom: 30px;
  z-index: 10;
  text-align: center;
  width: 100%;
}

.cta-buttons-inner {
  margin: 0 auto;
}

.carousel-indicators {
  display: none;
}

.carousel-inner {
  background-color: rgb(0 0 0 / 60%);
}

#hero .carousel-item::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.2) 35%, rgba(255, 255, 255, 0) 100%);
}