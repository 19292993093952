.ant-skeleton.skeleton-data-virtual>.ant-skeleton-content>.ant-skeleton-title {
    margin-top: 0;
}

.ant-skeleton.skeleton-data-virtual>.ant-skeleton-content>.ant-skeleton-paragraph {
    margin-bottom: 0;
}

.description ul.lichtrinh {
    list-style: none;
    padding: 0px;
}

.description ul.lichtrinh li .destinations {
    background: #e6f7ff;
    padding: 10px;
    border-left: 5px solid #1ba0e2;
    margin-left: -12px;
    margin-right: -12px;
    font-size: 15px;
    margin-bottom: 15px;
}

.description ul.lichtrinh li img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-bottom: 15px;
}

.description ul.lichtrinh li h3 {
    display: none;
}

.description ul.lichtrinh li p {
    font-size: 16px;
}

.description img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-bottom: 15px;
}


.mobile-header-custom {
    display: flex;
    width: 100%;
}

.mobile-header-custom>.mobile-header-custom-search {
    flex: 1;
}

.mobile-header-custom>.mobile-header-custom-cancel-search {
    margin-left: 10px;
    line-height: 30px;
    font-size: 15px;
}

.mobile-container .adm-infinite-scroll {
    padding: 0;
}

.body-mobile-container, .body-mobile-container * {
    scrollbar-width: none !important;
}

.map-timeline>.ant-timeline-item.map-timeline-from {
    margin-top: 40px;
}

.map-timeline>.ant-timeline-item.map-timeline-from>.ant-timeline-item-tail {
    border-left-style: dotted;
}

.adm-nav-bar.map-nav-bar {
    width: 100%;
    height: 120px;
}

.adm-nav-bar.map-nav-bar>.adm-nav-bar-title {
    width: calc(100% - 60px);
}