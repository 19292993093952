.ant-modal.modal-page-builder-preview {
    max-width: 100vw;
    min-width: 100vw;
    width: 100vw;
    top: 0;
    padding: 0;
}

.ant-modal.modal-page-builder-preview>.ant-modal-content {
    height: 100vh;
}

.ant-modal.modal-page-builder-preview>.ant-modal-content>.ant-modal-body {
    min-height: calc(100vh - 55px);
    max-height: calc(100vh - 55px);
    height: calc(100vh - 55px);
    overflow: auto;
    padding: 0;
}

.page-builder-component-item>.ant-alert>.ant-alert-icon {
    min-width: 40px;
}

.temp-display-news {
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 10px;
    display: block;
}

.temp-display-news.ant-radio-wrapper-checked, .temp-display-news:hover {
    border-color: #1890ff;
}

.temp-display-news .card-1 .ant-card-body {
    padding: 2px;
}

.temp-display-news .card-1 .ant-image.ant-image-error {
    height: 60px;
    overflow: hidden;
}

.temp-display-news .card-1 .ant-image.ant-image-error .ant-image-img {
    margin-top: -20px;
}

.temp-display-news .ant-skeleton .ant-skeleton-title {
    margin: 5px 0;
    height: 8px;
}

.temp-display-news .ant-skeleton .ant-skeleton-paragraph {
    margin: 5px 0;
}

.temp-display-news .ant-skeleton .ant-skeleton-paragraph li {
    height: 8px;
}

.design-page-news .ant-skeleton-title, .preview-page-news .ant-skeleton-title {
    margin-top: 0px !important;
}

.navigation-properties-menu-item>.ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
}

.navigation-properties-menu-item .icon-move {
    opacity: 0;
    cursor: pointer;
}

.navigation-properties-menu-item .icon-move-disabled {
    cursor: no-drop;
    color: #ccc;
}

.navigation-properties-menu-item:hover .icon-move {
    opacity: 1;
}

/* Menu */

/* .ant-menu-horizontal.page-menu {
    padding: 0 40px;
} */

.ant-menu-horizontal.page-menu>.ant-menu-item, .ant-menu-horizontal.page-menu>.ant-menu-submenu {
    border-bottom: 2px solid transparent !important;
    position: relative;
    margin-right: 0;
    margin-left: 0;
}

.page-menu-submenu-popup .ant-menu-item, .page-menu-submenu-popup .ant-menu-submenu {
    border-left: 3px solid transparent;
}

.page-menu-submenu-popup .ant-menu-item.ant-menu-item-active, .page-menu-submenu-popup .ant-menu-item.ant-menu-item-selected, .page-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-active {
    background-color: #f5f5f5;
    border-left: 3px solid;
}

.ant-menu-horizontal.page-menu>.ant-menu-item>.ant-menu-title-content, .ant-menu-horizontal.page-menu>.ant-menu-submenu>.ant-menu-submenu-title {
    padding: 0 20px;
    font-size: 15px;
}

.ant-menu-horizontal.page-menu>.ant-menu-item::after, .ant-menu-horizontal.page-menu>.ant-menu-submenu::after {
    content: '';
    position: absolute;
    bottom: -2px;
    height: 3px;
    width: 0;
    left: 50%;
    transition: all .3s;
    border-bottom: 3px solid;
}

.page-menu .ant-menu-item:hover .ant-menu-title-content, .page-menu .ant-menu-submenu:hover .ant-menu-submenu-title {
    color: inherit !important;
}

.ant-menu-horizontal.page-menu>.ant-menu-item.ant-menu-item-active, .ant-menu-horizontal.page-menu>.ant-menu-item.ant-menu-item-selected, .ant-menu-horizontal.page-menu>.ant-menu-submenu.ant-menu-submenu-active {
    background-color: #f5f5f5;
}

.ant-menu-horizontal.page-menu>.ant-menu-item.ant-menu-item-active::after, .ant-menu-horizontal.page-menu>.ant-menu-submenu.ant-menu-submenu-active::after {
    left: 0;
    width: 100%;
}

.new-list-child .ant-list-item:first-child {
    padding-top: 0;
}