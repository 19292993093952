.line-more{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
@for $i from 0 through 1000 {
    .line-more-#{$i} {
        -webkit-line-clamp: #{$i};
    }
}
