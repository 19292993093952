$l-c1: #1384ea;
$l-c1: #1384ea;

$d-c1: #2d3446;
$d-c2: #2d3446;

@mixin bgrcl($key) {
    background-color: $key;
}

@mixin cl($theme, $cl) {
    // $value:#ffff
}

.layout-header.ant-layout-header {
    z-index: 2;
    width: 100%;
    padding: 0 20px;
    color: #fff;
    @include bgrcl($l-c1);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
    .ant-btn {
        color: #fff;
    }
    .ant-btn-text:hover,
    .ant-btn-text:focus {
        background: rgba(0, 0, 0, 0.13);
    }
}
.layout-header-account-overlay {
    .ant-dropdown-menu {
        min-width: 200px;
    }
}

.layout-header-notification-overlay {
    .ant-dropdown-menu {
        padding: 0;
        width: 300px;
        background: none;
        color: #fff;
        .ant-spin-nested-loading {
            .ant-spin-container {
                background-color: #fff;
                color: rgba(0, 0, 0, 0.85);
            }
        }
        .ant-dropdown-menu-header {
            background-color: #2f92ec;
            border-radius: 2px 2px 0 0;
            .layout-header-notification-header {
                padding: 10px;
                display: flex;
                .layout-header-notification-header-title {
                    flex: 1;
                    font-weight: bold;
                }
            }
        }
        .ant-dropdown-menu-footer {
            background-color: #2f92ec;
            border-radius: 0 0 2px 2px;
            .layout-header-notification-footer {
                padding: 10px;
                text-align: center;
            }
        }
        .ant-dropdown-menu-items {
            margin-bottom: 0;
        }
    }
}

body[theme="drak"] {
    .layout-header.ant-layout-header {
        @include bgrcl($d-c1);
        .ant-btn-text:hover,
        .ant-btn-text:focus {
            background: rgba(255, 255, 255, 0.13);
        }
    }

    .layout-header-notification-overlay {
        .ant-dropdown-menu {
            color: #fff;
            .ant-spin-nested-loading {
                .ant-spin-container {
                    background-color: #6b7986;
                    color: #fff;
                }
            }
            .ant-dropdown-menu-header,
            .ant-dropdown-menu-footer {
                background-color: #244059;
            }
        }
    }
}
